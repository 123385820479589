import React from "react";
import { Link } from "react-router-dom";
const logo = require("../../assets/logo.png");

const Header = () => {
  return (
    <div className="sticky top-0 z-10 bg-white">
      <nav
        className="flex justify-between items-center text-black font-semibold 
        px-10 py-4 shadow-sm shadow-orange-200 w-full bg-white"
      >
        <a
          href="https://www.ligmr.fr/"
          className="flex items-center cursor-pointer"
        >
          <img src={logo} alt="logo" className="w-8 h-auto md:w-16 md:h-auto" />
        </a>
        <div className="flex justify-center items-center space-x-4">
          <Link
            to="/apply"
            className="text-sm sm:text-base md:text-lg lg:text-2xl xl:text-3xl text-orange-500 transition duration-300 ease-in-out hover:text-orange-600 border-b-2 border-transparent hover:border-orange-500 py-1 whitespace-nowrap"
          >
            <span>Application Form</span>
          </Link>
        </div>
        <div className="hidden md:block">{""}</div>
      </nav>
    </div>
  );
};

export default Header;
