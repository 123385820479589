import React from "react";
import Form from "../Form_Components/Form.jsx";

const Main = () => {
  return (
    <div className="">
      <Form />
    </div>
  );
};
export default Main;
