import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import Main from "./Components/Require_Components/Main.jsx";
import Header from "./Components/Require_Components/Header.jsx";
function App() {
  return (
    <Router>
      <Header />
      <ToastContainer />
      <Routes initialRouteName="apply">
        <Route path="/apply" element={<Main />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  );
}

export default App;
