import { AllCountryPhoneNumberDetails } from "./PhoneNumberLength";
import * as EmailValidator from "email-validator";
export const validatePhoneNumber = (length, countryCode) => {
  let isValid = false;
  AllCountryPhoneNumberDetails.map((phoneDetails) => {
    if (phoneDetails.code === countryCode) {
      if (Array.isArray(phoneDetails.phoneLength)) {
        if (phoneDetails.phoneLength.includes(length)) isValid = true;
        else isValid = false;
      } else {
        console.log(typeof phoneDetails.phoneLength, typeof length);
        if (phoneDetails.phoneLength === length) {
          return (isValid = true);
        } else isValid = false;
      }
    }
  });
  return isValid;
};

export const validateForm = (formState, phoneNumber) => {
  const requiredFields = [
    "title",
    "lastName",
    "firstName",
    "address",
    "zip",
    "phoneNumber",
    "dob",
    "city",
    "email",
    "passportNo",
    "state",
    "country",
    "currentSchool",
    "currentDegree",
    "knowFrom",
    "agency",
    "agencyEmail",
    "degreeType",
    "intake",
    "diploma",
    "bba",
    "bbaDegree",
    "mba",
    "mbaDegree",
    "bts",
    "passportCopy",
    "relevantDiploma",
    "resume",
    // "coverLetter",
    "marksheet",
    // "idPhoto",
  ];

  const emptyFields = requiredFields.filter((field) => {
    if (field === "email") {
      return !EmailValidator.validate(formState[field]);
    } else if (field === "passportNo") {
      return formState[field].length === 0;
    } else if (field === "agency" || field === "agencyEmail") {
      if (formState["knowFrom"] === "Agency") {
        if (formState[field] === "") return true;
        else return false;
      } else return false;
    } else if (
      (field === "bbaDegree" || field === "bba") &&
      formState["diploma"] === "BBA"
    ) {
      return !formState[field];
    } else if (
      (field === "mbaDegree" || field === "mba") &&
      formState["diploma"] === "MBA"
    ) {
      return !formState[field];
    } else if (field === "bts" && formState["diploma"] === "BTS") {
      return !formState[field];
    } else if (
      field === "bbaDegree" ||
      field === "bba" ||
      field === "mbaDegree" ||
      field === "mba" ||
      field === "bts"
    )
      return false;
    else {
      return !formState[field];
    }
  });
  return emptyFields;
};

export const secondPanelValidation = (formState) => {
  const requiredFields = [
    "degreeType",
    "intake",
    "diploma",
    "bba",
    "bbaDegree",
    "mba",
    "mbaDegree",
    "bts",
  ];
  const emptyFields = requiredFields.filter((field) => {
    if (field === "email") {
      return !EmailValidator.validate(formState[field]);
    } else if (field === "passportNo") {
      return formState[field].length === 0;
    } else if (field === "agency" || field === "agencyEmail") {
      if (formState["knowFrom"] === "Agency") {
        if (formState[field] === "") return true;
        else return false;
      } else return false;
    } else if (
      (field === "bbaDegree" || field === "bba") &&
      formState["diploma"] === "BBA"
    ) {
      return !formState[field];
    } else if (
      (field === "mbaDegree" || field === "mba") &&
      formState["diploma"] === "MBA"
    ) {
      return !formState[field];
    } else if (field === "bts" && formState["diploma"] === "BTS") {
      return !formState[field];
    } else if (
      field === "bbaDegree" ||
      field === "bba" ||
      field === "mbaDegree" ||
      field === "mba" ||
      field === "bts"
    )
      return false;
    else {
      return !formState[field];
    }
  });
  return emptyFields;
};

export const firstPanelValidation = (formState) => {
  const requiredFields = [
    "title",
    "lastName",
    "firstName",
    "address",
    "zip",
    "city",
    "dob",
    "phoneNumber",
    "email",
    "passportNo",
    "state",
    "country",
    "currentSchool",
    "currentDegree",
    "knowFrom",
    "agency",
    "agencyEmail",
  ];

  const emptyFields = requiredFields.filter((field) => {
    if (field === "email") {
      return !EmailValidator.validate(formState[field]);
    } else if (field === "passportNo") {
      return formState[field].length === 0;
    } else if (field === "agency" || field === "agencyEmail") {
      if (formState["knowFrom"] === "Agency") {
        if (formState[field] === "") return true;
        else return false;
      } else return false;
    } else if (
      (field === "bbaDegree" || field === "bba") &&
      formState["diploma"] === "BBA"
    ) {
      return !formState[field];
    } else if (
      (field === "mbaDegree" || field === "mba") &&
      formState["diploma"] === "MBA"
    ) {
      return !formState[field];
    } else if (field === "bts" && formState["diploma"] === "BTS") {
      return !formState[field];
    } else if (
      field === "bbaDegree" ||
      field === "bba" ||
      field === "mbaDegree" ||
      field === "mba" ||
      field === "bts"
    )
      return false;
    else {
      return !formState[field];
    }
  });
  return emptyFields;
};

export const validateFiles = (formState) => {
  const fileFields = [
    "passportCopy",
    "relevantDiploma",
    "resume",
    // "coverLetter",
    "marksheet",
    // "idPhoto",
  ];

  const uniqueFiles = {};

  fileFields.forEach((fieldName) => {
    if (!uniqueFiles[formState[fieldName + "Name"]]) {
      uniqueFiles[formState[fieldName + "Name"]] = [];
    }
    uniqueFiles[formState[fieldName + "Name"]].push(fieldName);
  });

  return uniqueFiles;
};
