import axios from "axios";

export const FormSubmissionEmail = async (obj) => {
  const { firstName, lastName, diploma, email } = obj;
  let first_name = firstName.toUpperCase();
  let last_name = lastName.toUpperCase();
  let designation = diploma === "BBA" ? "BBI" : diploma + " Program";

  try {
    let data = JSON.stringify({
      email,
      data: {
        first_name,
        last_name,
        designation,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.mailmodo.com/api/v1/triggerCampaign/3b069ab3-142a-523f-9b82-b54e54f0b53e",
      headers: {
        mmApiKey: "ATM6PMF-SZ24ES9-H5308H2-4YN6T1J",
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios(config);
    if (response.data.success) {
      console.log("Email Sent successfully");
    } else {
      console.log("Email not sent");
    }
  } catch (error) {
    console.error("error", error); // Log the error for debugging purposes

    if (error.response && error.response.status === 400) {
      console.log("Invalid Email");
    } else {
      console.log("Email not sent");
    }
  }
};

export const FormSubmissionEmailAdmin = async (obj) => {
  const { firstName, lastName, diploma } = obj;
  let email = "admissions@lyoninstitute.edu.eu";
  let first_name = firstName.toUpperCase();
  let last_name = lastName.toUpperCase();
  let designation = diploma === "BBA" ? "BBI" : diploma + " Program";

  try {
    let data = JSON.stringify({
      email,
      data: {
        first_name,
        last_name,
        designation,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.mailmodo.com/api/v1/triggerCampaign/3b069ab3-142a-523f-9b82-b54e54f0b53e",
      headers: {
        mmApiKey: "ATM6PMF-SZ24ES9-H5308H2-4YN6T1J",
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios(config);
    if (response.data.success) {
      console.log("Email Sent successfully");
    } else {
      console.log("Email not sent");
    }
  } catch (error) {
    console.error("error", error); // Log the error for debugging purposes

    if (error.response && error.response.status === 400) {
      console.log("Invalid Email");
    } else {
      console.log("Email not sent");
    }
  }
};

export const FormSubmissionEmailBDM = async (obj) => {
  const { firstName, lastName, diploma } = obj;
  let email = "india@lyoninstitute.edu.eu";
  let first_name = firstName.toUpperCase();
  let last_name = lastName.toUpperCase();
  let designation = diploma === "BBA" ? "BBI" : diploma + " Program";

  try {
    let data = JSON.stringify({
      email,
      data: {
        first_name,
        last_name,
        designation,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.mailmodo.com/api/v1/triggerCampaign/3b069ab3-142a-523f-9b82-b54e54f0b53e",
      headers: {
        mmApiKey: "ATM6PMF-SZ24ES9-H5308H2-4YN6T1J",
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios(config);
    if (response.data.success) {
      console.log("Email Sent successfully");
    } else {
      console.log("Email not sent");
    }
  } catch (error) {
    console.error("error", error); // Log the error for debugging purposes

    if (error.response && error.response.status === 400) {
      console.log("Invalid Email");
    } else {
      console.log("Email not sent");
    }
  }
};
