import client from "./client";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    credentials: "include",
  },
};

export const studentRegister = async (obj) => {
  try {
    const { data } = await client.post("/student/register", obj, config);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// obj = {email}
export const sendVerification = async (obj) => {
  try {
    const { data } = await client.post("/student/sendVerification", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const sendVerificationMobile = async (obj) => {
  try {
    const { data } = await client.post(
      "/student/sendVerificationPhoneNumber",
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const sendPassportVerification = async (obj) => {
  try {
    const { data } = await client.post(
      "/student/sendPassportVerification",
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const checkPhoneNumberInDb = async (obj) => {
  try {
    const { data } = await client.post("/student/checkPhoneNumberInDb", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

//  obj = { email, code }
export const verifyEmail = async (obj) => {
  try {
    const { data } = await client.post("/student/verifyEmail", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
