import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const BBAComponent = ({ formState, handleInputChange }) => {
  // Dummy data for program and degree options
  const programOptions = [
    {
      label: "BBI International Management & Entrepreneurship",
      value: "bbaentrepreneurship",
    },
    {
      label: "BBI International Marketing & Communication",
      value: "bbadigitalMarketing",
    },
    { label: "BBI International Finance", value: "bbafinance" },
    {
      label: "BBI Business Logistics & Operation Management",
      value: "bbascomanagement",
    },
    {
      label: "HOSPITALITY AND HOTEL BUSINESS",
      value: "HOSPITALITY_AND_HOTEL_BUSINESS",
    },
  ];

  const degreeOptions = {
    bbaentrepreneurship: [
      "BBI 1st Year (Bac+1)",
      "BBI 2nd Year (Bac+2)",
      "BBI 3rd Year (Bac+3)",
    ],
    bbadigitalMarketing: [
      "BBI 1st Year (Bac+1)",
      "BBI 2nd Year (Bac+2)",
      "BBI 3rd Year (Bac+3)",
    ],
    bbafinance: [
      "BBI 1st Year (Bac+1)",
      "BBI 2nd Year (Bac+2)",
      "BBI 3rd Year (Bac+3)",
    ],
    bbascomanagement: [
      "BBI 1st Year (Bac+1)",
      "BBI 2nd Year (Bac+2)",
      "BBI 3rd Year (Bac+3)",
    ],
    HOSPITALITY_AND_HOTEL_BUSINESS: [
      "BBI 1st Year (Bac+1)",
      "BBI 2nd Year (Bac+2)",
      "BBI 3rd Year (Bac+3)",
    ],
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-4">
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="diploma-label">BBA</InputLabel>
          <Select
            labelId="bba-label"
            id="bba"
            name="bba"
            value={formState.bba}
            onChange={handleInputChange}
            label="bbaDegree"
          >
            {programOptions.map((program) => (
              <MenuItem key={program.value} value={program.value}>
                {program.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="diploma-label">BBA Degree</InputLabel>
          <Select
            labelId="bbadegree-label"
            id="bbaDegree"
            name="bbaDegree"
            value={formState.bbaDegree}
            onChange={handleInputChange}
            label="bbaDegree"
          >
            {degreeOptions[
              formState.bba !== "" ? formState.bba : "bbaentrepreneurship"
            ]?.map((degree) => (
              <MenuItem key={degree} value={degree}>
                {degree}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
};

export default BBAComponent;
